import * as Yup from 'yup'
import * as WebUI from '@cheddarup/web-ui'
import {useRef, useState} from 'react'
import {Link} from 'src/components/Link'
import {Logo} from 'src/components'
import config from 'src/config'
import {useFormik, useInterval} from '@cheddarup/react-util'
import {useLogin} from 'src/hooks/useAuth'
import {ApiError, useConvertTemplateToTabMutation} from '@cheddarup/api-client'
import {BooleanParam, useQueryParam} from 'use-query-params'
import queryString from 'query-string'
import {AuthBackground} from 'src/components/AuthBackground'

const LoginPage = () => (
  <div className="flex h-screen flex-row bg-natural-100">
    <AuthBackground className="hidden max-w-screen-sm shrink grow lg:block">
      <div className="flex flex-col gap-4">
        <WebUI.Heading className="font-accentAlt text-h-1">
          Welcome Back!
        </WebUI.Heading>
        <WebUI.Text className="text-ds-lg">
          Did you know that your group can pay online without an app or creating
          an account? It’s that easy.
        </WebUI.Text>
      </div>
    </AuthBackground>
    <div className="flex grow basis-0 flex-col gap-8 p-8 pt-0 sm:justify-center sm:p-24">
      <div className="-mx-8 flex flex-row bg-natural-80 px-7 py-5 sm:hidden">
        <WebUI.Anchor href={config.links.marketingPage} target="_blank">
          <Logo
            className="gap-2 self-start lg:hidden"
            forceDisplayText
            iconVariant="flat"
          />
        </WebUI.Anchor>
      </div>
      <LoginForm />
    </div>
  </div>
)

// MARK: – LoginForm

const LoginForm = () => {
  const convertTemplateToTabMutation = useConvertTemplateToTabMutation()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const recaptchaRef = useRef<WebUI.RecaptchaInstance>(null)
  const [secondsLeftToResendSms, setSecondsLeftToResendSms] = useState(0)
  const [secondsLeftToResendCall, setSecondsLeftToResendCall] = useState(0)
  const [requiredTwoFactor, setRequiredTwoFactor] = useState(false)
  const growlActions = WebUI.useGrowlActions()
  const [, setPreventAuthRedirect] = useQueryParam(
    'preventAuthRedirect',
    BooleanParam,
  )
  const [login] = useLogin({recaptchaRef})

  function resetSecondsLeft(via: 'sms' | 'call') {
    if (via === 'sms') {
      setSecondsLeftToResendSms(45)
    } else if (via === 'call') {
      setSecondsLeftToResendCall(60)
    }
  }

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Email is Invalid').required('Required'),
      password: Yup.string().required('Required'),
    }),
    initialValues: {
      email: '',
      password: '',
      otp_code: '',
      via: 'sms' as 'sms' | 'call',
    },
    onSubmit: async (values) => {
      try {
        const queryParams = queryString.parse(window.location.search)
        if (typeof queryParams.templateId === 'string') {
          setPreventAuthRedirect(true)
        }
        await login(values)

        if (typeof queryParams.templateId === 'string') {
          await convertTemplateToTabMutation
            .mutateAsync({
              pathParams: {
                templateId: queryParams.templateId,
              },
            })
            .catch(() => {
              // noop
            })
          setPreventAuthRedirect(false)
        }
      } catch (err) {
        if (
          err instanceof ApiError &&
          err.significantCode === 'two_factor_required'
        ) {
          setRequiredTwoFactor(true)
          resetSecondsLeft(values.via)
        } else if (err instanceof Error) {
          setErrorMessage(err.message)
        }
      }
    },
  })

  async function resendOtpCode(via: 'sms' | 'call') {
    resetSecondsLeft(via)
    // for resending otp
    formik.setFieldValue('otp_code', '')
    await formik.setFieldValue('via', via)
    formik.submitForm()
    growlActions.show('success', {
      title: 'Success',
      body: 'Verification code sent to your phone',
    })
  }

  useInterval(() => {
    setSecondsLeftToResendSms((prev) => Math.max(prev - 1, 0))
    setSecondsLeftToResendCall((prev) => Math.max(prev - 1, 0))
  }, 1000)

  return (
    <form className="flex flex-col gap-5" onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <WebUI.Text className="font-black text-ds-xl">
            Sign in to your account
          </WebUI.Text>
          <WebUI.Text>
            Don't have an account?{' '}
            <Link variant="primary" preserveSearch to="/signup">
              Create one
            </Link>
          </WebUI.Text>
        </div>
        <WebUI.FloatingLabelFormTextField
          label="Email"
          error={formik.errors.email}
        >
          <WebUI.Input
            type="email"
            name="email"
            size="xl"
            roundness="capsule"
            autoComplete="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={requiredTwoFactor}
          />
        </WebUI.FloatingLabelFormTextField>

        <WebUI.PasswordFloatingLabelFormTextFieldInput
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={requiredTwoFactor}
          error={formik.errors.password}
        />

        <Link className="self-start" to="forgot-password" variant="primary">
          Forgot your password?
        </Link>
      </div>
      <div className="flex flex-col">
        <WebUI.Disclosure visible={requiredTwoFactor}>
          <WebUI.DisclosureContent className="mb-5">
            <div className="flex flex-col gap-5 rounded-2xl bg-natural-80 p-7">
              <div className="flex flex-row items-center gap-5">
                <WebUI.PhosphorIcon
                  icon="shield-check-fill"
                  className="text-orange-50"
                  width={25}
                  height={30}
                />
                <WebUI.Heading as="h3" className="font-black text-ds-md">
                  Security Verification
                </WebUI.Heading>
              </div>
              <WebUI.Text className="font-light text-ds-base">
                A two-factor authentication code has been sent to your device.
                Enter the code to continue.
              </WebUI.Text>

              <WebUI.FormField>
                <WebUI.PinInput
                  className="h-16"
                  containerClassName="h-16"
                  name="otp_code"
                  disabled={formik.isSubmitting}
                  onComplete={(code) => formik.setFieldValue('otp_code', code)}
                />
              </WebUI.FormField>

              <div className="flex flex-col gap-3">
                <WebUI.Button
                  className="text-ds-sm aria-disabled:opacity-100"
                  variant="link"
                  type="button"
                  disabled={formik.isSubmitting || secondsLeftToResendSms > 0}
                  onClick={() => resendOtpCode('sms')}
                >
                  {secondsLeftToResendSms > 0
                    ? `Seconds left to resend code via text: ${secondsLeftToResendSms}`
                    : 'Send code via text'}
                </WebUI.Button>

                <WebUI.Button
                  className="text-ds-sm aria-disabled:opacity-100"
                  variant="link"
                  type="button"
                  disabled={formik.isSubmitting || secondsLeftToResendCall > 0}
                  onClick={() => resendOtpCode('call')}
                >
                  {secondsLeftToResendCall > 0
                    ? `Seconds left to resend code via voice call: ${secondsLeftToResendCall}`
                    : 'Call me with code'}
                </WebUI.Button>
              </div>
            </div>
          </WebUI.DisclosureContent>
        </WebUI.Disclosure>

        <WebUI.RoundedButton
          className="w-[180px]"
          type="submit"
          variant="primary"
          loading={formik.isSubmitting}
          disabled={requiredTwoFactor && !formik.values.otp_code}
        >
          {requiredTwoFactor ? 'Continue' : 'Sign In'}
        </WebUI.RoundedButton>
      </div>
      {config.isProd && <WebUI.Recaptcha ref={recaptchaRef} />}
      {!!errorMessage && (
        <WebUI.Text className="ml-6 text-ds-sm text-orange-50">
          {errorMessage}
        </WebUI.Text>
      )}
    </form>
  )
}

export default LoginPage
